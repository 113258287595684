
import EvercamPlayer from "@evercam/shared/components/EvercamPlayer"
import PlayersActionButtons from "@/components/recordings/player/PlayersActionButtons"
import SnapshotEditor from "@/components/SnapshotEditor"
import PtzBox from "@/components/ptz/PtzBox"

import { SECOND } from "@evercam/shared/constants/schedules"
import Vue from "vue"
import { mapStores } from "pinia"
import { useLayoutStore } from "@/stores/layout"
import { useRecordingsStore } from "@/stores/recordings"
import { useCameraStore } from "@/stores/camera"
import { useAccountStore } from "@/stores/account"
import { camelizeKeys } from "humps"
import { RouteParams } from "@evercam/shared/types/routeParams"
import { useSnapshotStore } from "@/stores/snapshots"
import { useConnectorStore } from "@/stores/connector"
import {
  AnalyticsEvent,
  AnalyticsEventPageId,
  CameraFeatureFlag,
} from "@evercam/shared/types"
import { useWeatherStore } from "@/stores/weather"
import { useProjectStore } from "@/stores/project"
import { inactivityListener } from "@evercam/ui"
import { updateQuery } from "@evercam/shared/utils"
import CameraStatusSnackbar from "@/components/CameraStatusSnackbar.vue"

export default Vue.extend({
  name: "Live",
  meta: {
    publiclyAccessible: true,
    pageId: AnalyticsEventPageId.Liveview,
  },
  components: {
    EvercamPlayer,
    PlayersActionButtons,
    SnapshotEditor,
    PtzBox,
    CameraStatusSnackbar,
  },
  mixins: [inactivityListener],
  middleware: [
    "ensureCameraRoute",
    ({ redirect }) => {
      const cameraStore = useCameraStore()
      const accountStore = useAccountStore()
      if (!cameraStore.selectedCamera && !accountStore.token) {
        redirect("/v2/users/signin")
      }
    },
  ],
  async asyncData({ redirect, query, app, route }) {
    const recordingsStore = useRecordingsStore()
    const cameraStore = useCameraStore()
    const accountStore = useAccountStore()
    const camelizedKeys = camelizeKeys(route.params) as RouteParams

    const routeCameraExid = camelizedKeys?.cameraExid

    if (cameraStore.selectedCamera && !cameraStore.hasCameraLiveView) {
      redirect(`${cameraStore.cameraRoute}/recordings`)
    }

    let customSettings = {}
    if (query["refresh-rate"]) {
      customSettings = {
        refreshRate: (parseInt(query["refresh-rate"] as string) - 1) * SECOND,
      }
    } else if (["https-bhgfx", "kajim-qntwd"].includes(routeCameraExid)) {
      customSettings = {
        refreshRate: 60 * SECOND,
        schedule: [8, 18],
      }
    }

    if (app.$permissions.camera.has.edgeVideoStreaming()) {
      recordingsStore.createEdgeStreamingSession({
        cameraExid: cameraStore.selectedCamera?.id,
        token: accountStore.token,
      })
    } else if (cameraStore.selectedCamera) {
      cameraStore.liveViewSocket = cameraStore.connectLiveViewSocket(
        cameraStore.selectedCamera.streamingServer
      )
    }

    return {
      customSettings,
    }
  },
  data() {
    return {
      customSettings: {} as {
        refreshRate: number
        schedule: number[]
      },
      playerDimensions: {} as { width: string; height: string },
      defaultBoundingRect: {
        bottom: 0,
        height: 0,
        left: 0,
        right: 0,
        top: 0,
        width: 0,
        x: 0,
        y: 0,
      },
      editorTranslation: 0,
      isLoading: false,
      isPlaying: false,
    }
  },
  head() {
    const classList: string[] = []
    if (this.$vuetify.breakpoint.mdAndUp) {
      classList.push("overflow-y-hidden")
    }

    return {
      title: `${this.cameraStore.selectedCamera?.name} | ${this.$t(
        "pages.live_view"
      )}`,
      meta: [
        { charset: "utf-8" },
        {
          hid: "description",
          name: "description",
          content: "TIME-LAPSE & PROJECT MANAGEMENT CAMERAS",
        },
      ],
      htmlAttrs: {
        class: classList.join(" "),
      },
    }
  },
  computed: {
    ...mapStores(
      useRecordingsStore,
      useLayoutStore,
      useCameraStore,
      useAccountStore,
      useSnapshotStore,
      useWeatherStore,
      useProjectStore,
      useConnectorStore
    ),
    isPtzBoxEnabled(): boolean {
      return (
        this.cameraStore.selectedCamera?.featureFlags.includes(
          CameraFeatureFlag.PTZ
        ) &&
        this.cameraStore.isCameraOnline &&
        !this.accountStore.isLiveViewWidget &&
        !!this.accountStore.token
      )
    },
    editorTranslationStyles(): { transform: string } {
      return {
        transform: `translate(0, ${this.editorTranslation}px)`,
      }
    },
    hasLiveView(): boolean {
      return (
        this.cameraStore.hasCameraLiveView ||
        this.$permissions.camera.has.edgeVideoStreaming()
      )
    },
    timezone(): string {
      return this.cameraStore.selectedCameraTimezone
    },
    disbalePtz(): boolean {
      return this.cameraStore.selectedCamera?.rights
        ?.split(",")
        .some((o) => ["edit", "share"].includes(o))
    },
    isLivePausableOnIdle(): boolean {
      return this.$route.query.pause !== "false"
    },
  },
  watch: {
    "layoutStore.isFullscreen"() {
      this.updatePlayerDimensions()
    },
    "cameraStore.isCameraTab"() {
      this.updatePlayerDimensions()
    },
    "recordingsStore.isEditing"(v) {
      this.$setTimeout(this.updatePlayerDimensions)
      const translationStart = v ? -this.layoutStore.footerRect.height : 0
      const translationEnd = v ? 0 : -this.layoutStore.footerRect.height

      this.editorTranslation = translationStart
      this.$nextTick(() => {
        this.$setTimeout(() => {
          this.editorTranslation = translationEnd
        })
      })
    },
    "recordingsStore.isLive": {
      handler(value) {
        updateQuery({ live: value || undefined })
      },
      immediate: true,
      deep: true,
    },
  },
  created() {
    this.recordingsStore.isLive = true
  },
  beforeDestroy() {
    this.recordingsStore.reset()
  },
  mounted() {
    this.$analytics.saveEvent(AnalyticsEvent.PageView)
    this.layoutStore.fullscreenElement = this.$globalRefs.liveViewContainer
    this.updatePlayerDimensions()
    this.$registerInactivityListener({
      callback: this.onidle,
      duration: 120,
      reminders: [110],
    })
  },
  methods: {
    async onidle() {
      const shouldPause =
        this.cameraStore.isCameraOnline &&
        this.isLivePausableOnIdle &&
        this.recordingsStore.isPlaying &&
        !this.isLoading

      if (this.recordingsStore.isEditing || !shouldPause) {
        return
      }

      this.recordingsStore.isLive = false
      this.recordingsStore.isPlaying = false
      if (this.weatherStore.dialog) {
        return
      }

      const isConfirmed = await this.$confirmDialog.open({
        title: this.$t("content.live_view.pause_live_view").toString(),
        message: this.$t("content.live_view.inactive_live_view").toString(),
        options: {
          width: 360,
          confirmText: this.$t("actions.resume").toString(),
        },
      })

      if (isConfirmed) {
        this.recordingsStore.isLive = true
      }
    },
    updatePlayerDimensions() {
      const cameraTabsHeight =
        this.$globalRefs.cameraTabs?.getBoundingClientRect()?.height || 0
      const appBarHeight =
        this.$globalRefs.appBar?.getBoundingClientRect()?.height || 0
      const topBarsHeight = cameraTabsHeight + appBarHeight
      const leftSideBarWidth =
        this.$globalRefs.sideBar?.getBoundingClientRect()?.width || 0
      const rightSidebarWidth = this.layoutStore.isRightSidebarVisible
        ? this.$globalRefs.rightSidebar?.getBoundingClientRect()?.width || 0
        : 0

      this.playerDimensions = {
        width: window.innerWidth,
        height: window.innerHeight - topBarsHeight,
      }
      if (this.$vuetify.breakpoint.lgAndUp) {
        this.playerDimensions = {
          ...this.playerDimensions,
          width: window.innerWidth - rightSidebarWidth - leftSideBarWidth,
        }
      }
    },
  },
})
